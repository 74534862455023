<template>
  <div style="background:#FFFFFF">
    <div class="header-nav">
      <span class="hn-one" @click="returnFor">门店管理</span>
      <a-icon class="hn-two" type="right" />
      <span v-if="enterpriseInfoBase">{{enterpriseInfoBase.fullName}}</span>
    </div>
    <div class="container">
      <div class="header"><span class="line"></span> 基本信息</div>
      <ul class="content" v-if="enterpriseInfoBase">
        <li>
          <span>企业名称</span>
          <div>{{enterpriseInfoBase.enterpriseName||'--'}}</div>
        </li>
        <li>
          <span>企业联系电话</span>
          <div>{{enterpriseInfoBase.servicePhone||'--'}}</div>
        </li>
        <li>
          <span>企业地址</span>
          <div>{{enterpriseInfoBase.provinceText}}/{{enterpriseInfoBase.cityText}}</div>
        </li>
        <li>
          <span>详细地址</span>
          <div>{{enterpriseInfoBase.address||'--'}}</div>
        </li>
        <li>
          <span>企业联系人</span>
          <div>{{enterpriseInfoBase.chargePerson||'--'}}</div>
        </li>
      </ul>
      <div class="header"><span class="line"></span> 证照信息</div>
      <ul class="content" v-if="enterpriseInfoBase">
        <li>
          <span>证件类型</span>
          <div>{{enterpriseInfoBase.legalPersonLicenseType==='01'?'身份证':enterpriseInfoBase.legalPersonLicenseType==='04'?'港澳通行证':enterpriseInfoBase.legalPersonLicenseType==='05'?'台湾通行证':'--'}}</div>
        </li>
        <li>
          <span>证件有效期</span>
          <div>{{enterpriseInfoBase.legalPersonLicenseValidity||'--'}}</div>
        </li>
        <li>
          <span>企业类型</span>
          <div>{{enterpriseInfoBase.industryText||'--'}}</div>
        </li>
        <li>
          <span>企业信用代码</span>
          <div>{{enterpriseInfoBase.licenseNo||'--'}}</div>
        </li>
        <li>
          <span>国别</span>
          <div>中国</div>
        </li>
        <li>
          <span>注册地址</span>
          <div>{{enterpriseInfoBase.address||'--'}}</div>
        </li>
        <li v-for="(item,index) in picturesList" :key="index">
          <span>{{item.name}}</span>
          <div><a v-if="item.url" @click="check(item.url,false)">查看</a><span v-else>--</span></div>
        </li>
      </ul>
      <div class="header"><span class="line"></span> 结算信息</div>
      <ul class="content" v-if="enterpriseInfoBase">
        <li>
          <span>银行名称</span>
          <div>{{enterpriseInfoBase.bankName||'--'}}</div>
        </li>
        <li>
          <span>银行编码</span>
          <div>{{enterpriseInfoBase.bankCode||'--'}}</div>
        </li>
        <li>
          <span>账户名称</span>
          <div>{{enterpriseInfoBase.accountName||'--'}}</div>
        </li>
        <li>
          <span>账户号</span>
          <div>{{enterpriseInfoBase.accountNo||'--'}}</div>
        </li>
      </ul>
      <div class="header"><span class="line"></span> 人员配置</div>
      <s-table
        ref="bmryTable"
        style="border-top:1px solid #F0F0F0;margin-top:16px"
        :columns="columnsBmry"
        :data="dataBmry"
        :rowKey="(record) => record.id"
      >
      </s-table>
      <a-modal
        title="图片信息"
        :visible="visible"
        :footer="null"
        width="550px"
        @cancel="visible=false"
      >
        <img :src="imageUrl" style="width:500px;height:500px;margin:0 auto;" alt="">
        <!-- <a-carousel arrows v-else>
          <div
            slot="prevArrow"
            slot-scope="props"
            class="custom-slick-arrow"
            style="left: 10px;zIndex: 1"
            :dots='position'
          >
            <a-icon type="left-circle" />
          </div>
          <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
            <a-icon type="right-circle" />
          </div>
          <div><img v-if="enterpriseInfo.enterpriseSitePhotos1" :src="enterpriseInfo.enterpriseSitePhotos1" style="width:500px;height:500px;margin:0 auto;" alt=""></div>
        </a-carousel> -->
      </a-modal>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { ACCESS_TOKEN,USER_INFO } from "@/store/mutation-types";
import STable from '@/components/table/';
import {
  merchanGet,
  getPictureByMerchantId,
  taskWorker,
  merchantServiceConfigList
} from "@/api/api";
export default {
  name: 'Home',
  components: {
    STable
  },
  data () {
    return {
      enterpriseInfo: null,
      visible: false,
      imageUrl: '',
      isCarsour: false,
      position:false,
      enterpriseInfoBase: null,
      id: '',
      picturesList: [
        {
          type: '100401',
          name: '身份证正面',
          url: null
        },
        {
          type: '100402',
          name: '身份证反面',
          url: null
        },
        {
          type: '100403',
          name: '门店场景',
          url: null
        },
        {
          type: '100404',
          name: '商户门头',
          url: null
        },
        {
          type: '100405',
          name: '营业执照',
          url: null
        },
        {
          type: '100406',
          name: '手持照片',
          url: null
        }
      ],
      columnsBmry: [
        {
          title: '管理用户名称',
          dataIndex: 'relationWorkerUserName',
        },
        {
          title: '关联任务名称',
          dataIndex: 'relationNoTitle',
        },
        {
          title: '提成比例(%)',
          dataIndex: 'rebatePoint',
          customRender: text => text===null?'--':(text*100)
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: text => text=== '10' ? '未开始' : text === '20' ? '服务中' : text=== '30' ? '服务完成':'服务失败'
        },
        {
          title: '服务开始日期',
          dataIndex: 'serviceBeginDate',
          customRender: text => text===null?'--':text
        },
        {
          title: '服务结束日期',
          dataIndex: 'serviceEndDate',
          customRender: text => text===null?'--':text
        }
      ],
    }
  },
  created () {
    this.id = window.localStorage.getItem('id')
    this.getDetail()
    this.getlicenseLists()
  },
  methods: {
    // 返回
    returnFor () {
      window.localStorage.setItem('submenu','storeManage')
      window.localStorage.removeItem('id')
      this.$router.push('/storeManage')
    },
    // 获取人员配置列表
    dataBmry (parameter) {
      return merchantServiceConfigList(Object.assign({merchantId:this.id},parameter)).then(res => {
        return res.result
      })
    },
    // 获取详情
    getDetail () {
      merchanGet(this.id).then(res => {
        if (res.success) {
          this.enterpriseInfoBase = res.result
        }
      })
    },
    // 获取证件信息
      getlicenseLists () {
        getPictureByMerchantId(this.id).then(res => {
          if (res.success) {
            if (res.result.length>0) {
              res.result.forEach(list=>{
                this.picturesList.forEach(item=>{
                  if (list.type===item.type) {
                    item.url = list.pictureUrl
                  }
                })
              })
            }
          }
        })
      },
    // 查看图片
    check(url,bl) {
      this.imageUrl = url;
      this.isCarsour = bl;
      this.visible = true;
    },
  }
}
</script>
<style lang="less" scoped>
.container{
  background: #FFFFFF;
  border-radius: 4px;
  // height: 935px;
  padding: 40px;
}
.header-nav{
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 14px;
  padding: 17px 0 17px 34px;
  border-bottom: 1px solid #DEDEDE;
  .hn-one{
    color: #000000;
    cursor: pointer;
  }
  .hn-two{
    margin: 0 15px;
    color: #DEDEDE;
    font-size: 18px;
  }
}
.header{
  .line{
    width: 2px;
    height: 18px;
    background: #347CF5;
    margin-right: 9px;
  }
  color: #333333;
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.nav{
  color: #283039;
  font-size: 14px;
  margin: 0px 0 0px 98px;
  font-weight: bold;
}
.content{
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 20px 80px;
  margin: 0;
  li{
    display: flex;
    width: 50%;
    box-sizing: border-box;
    align-items: center;
    font-size: 14px;
    color: #727682;
    span{
      width: 140px;
      padding: 10px 18px;
    }
    div{
      padding: 10px 18px;
      color: #333333;
    }
  }
}
</style>
<style scoped>
/* For demo */
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 500px;
  line-height: 160px;
  /* background: #364d79; */
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 35px;
  height: 35px;
  font-size: 35px;
  color: #fff;
  background-color: rgba(31, 45, 61, 1);
  border-radius: 50%;
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}
.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.ant-carousel >>> .slick-dots{
  display: none !important;
}
</style>
